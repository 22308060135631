
import { Vue, Component } from 'vue-property-decorator';
import LandingHero from '@/components/landingAndRegistrationComponents/LandingHero.vue';
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import LandingLabel from '@/components/landingAndRegistrationComponents/LandingLabel.vue';
import InsightsWhite from '@/components/creditpushMain/HomePage/InsightsWhite.vue';
import LandingFeatureGrid from '@/components/landingAndRegistrationComponents/LandingFeatureGrid.vue';
import { getNextPage } from '@/utils/redirectUtils';

@Component({
  components: {
    LandingHero,
    BaseButton,
    LandingLabel,
    InsightsWhite,
    LandingFeatureGrid,
  },
})
export default class LandingCarbonPage extends Vue {
  landingFeatures: any[] = [
    {
      title: 'Track your carbon footprint',
      description:
        'Keep an eye on the carbon footprint of your spending and see the footprint of every purchase you make',
      icon: 'carbon',
    },
    {
      title: 'Reduce high-emission spending',
      description:
        'For effective decarbonization and offset, look at your monthly footprint and top carbon contributors',
      icon: 'high-emission',
    },
    {
      title: 'Make your business greener',
      description:
        'Let the calculator guide you with all its tips, suggestions & insights on how to become an eco-conscious business leader',
      icon: 'business-green',
    },
  ];
  goToRegistration(): void {
    this.$router.push({ name: getNextPage() });
  }

  mounted(): void {
    this.$store.commit('flowStages/setCurrentFlowAndStage', { flowVersion: '700', pageName: this.$route.name });
  }
}
